import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Advanced_User/IOBroker_on_Raspberry_Pi/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IOBroker on a Raspberry Pi",
  "path": "/Advanced_User/IOBroker_on_Raspberry_Pi/Raspian_Preparation/",
  "dateChanged": "2018-09-20",
  "author": "Mike Polinowski",
  "excerpt": "ioBroker is an integration platform for the Internet of Things, focused on Building Automation, Smart Metering, Ambient Assisted Living, Process Automation, Visualization and Data Logging. Control your INSTAR IP camera with your Raspberry Pi. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "../AU_SearchThumb_ioBroker.png",
  "social": "/images/Search/AU_SearchThumb_ioBroker.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_ioBroker_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "ioBroker"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='IOBroker on a Raspberry Pi' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='ioBroker is an integration platform for the Internet of Things, focused on Building Automation, Smart Metering, Ambient Assisted Living, Process Automation, Visualization and Data Logging. Control your INSTAR IP camera with your Raspberry Pi. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_ioBroker.png' twitter='/images/Search/AU_SearchThumb_ioBroker.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/IOBroker_auf_Raspberry_Pi/Raspian_Preparation/' locationFR='/fr/Advanced_User/IOBroker_on_Raspberry_Pi/Raspian_Preparation/' crumbLabel="ioBroker" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "iobroker-on-a-raspberry-pi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#iobroker-on-a-raspberry-pi",
        "aria-label": "iobroker on a raspberry pi permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IOBroker on a Raspberry Pi`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#raspbian"
        }}>{`Raspbian`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#required-hardware"
            }}>{`Required Hardware`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#required-software"
            }}>{`Required Software`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#raspbian-installation"
            }}>{`Raspbian Installation`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "raspbian",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#raspbian",
        "aria-label": "raspbian permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Raspbian`}</h2>
    <p>{`We are going to use a clean installation of `}<a parentName="p" {...{
        "href": "https://www.raspberrypi.org/downloads/raspbian/"
      }}>{`Raspian`}</a>{` (a Debian based LINUX Dist for your Raspberry Pi) to set up ioBroker. Please be aware that there is also a `}<strong parentName="p">{`specific Distro for ioBroker`}</strong>{` that - in theory - sets up everything for you. No assembly required. But we ran into some issues and decided to do it all manually instead. But please feel free to try the `}<a parentName="p" {...{
        "href": "http://www.iobroker.net/docu/?page_id=5139&lang=en"
      }}>{`ioBroker OS`}</a>{` first - it might work for you and save you some time.`}</p>
    <h3 {...{
      "id": "required-hardware",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#required-hardware",
        "aria-label": "required hardware permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Required Hardware`}</h3>
    <ul>
      <li parentName="ul">{`Raspberry PI`}</li>
      <li parentName="ul">{`Power supply (it is important to have a good one. You will have stability problems with weak or bad quality power supplies)`}</li>
      <li parentName="ul">{`SD Card`}</li>
    </ul>
    <h3 {...{
      "id": "required-software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#required-software",
        "aria-label": "required software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Required Software`}</h3>
    <ul>
      <li parentName="ul">{`Download the `}<a parentName="li" {...{
          "href": "https://www.raspberrypi.org/downloads/raspbian/"
        }}>{`Raspian Image`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://etcher.io"
        }}>{`Etcher`}</a>{` to flash the Image to your SD Card (we already used it to `}<a parentName="li" {...{
          "href": "/en/Advanced_User/openHABian_on_Raspberry_Pi/#install-openhabian-on-a-raspberry-pi"
        }}>{`install openHABian`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.putty.org/"
        }}>{`Putty`}</a>{` or `}<a parentName="li" {...{
          "href": "https://mobaxterm.mobatek.net/download.html"
        }}>{`Mobaterm`}</a>{` to SSH into your Pi`}</li>
    </ul>
    <h3 {...{
      "id": "raspbian-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#raspbian-installation",
        "aria-label": "raspbian installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Raspbian Installation`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Insert the prepared SD card into your RaspberryPi, connect a LAN and power cable and wait 20-45min for the Operating System to install.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Connect to Raspberry via your SSH client. Login: `}<strong parentName="p">{`pi`}</strong>{`, Password: `}<strong parentName="p">{`raspberry`}</strong></p>
      </li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/27821a78f0ceee86b443837ca01ed12d/e9beb/IOBroker_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.91304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACZ0lEQVQoz12Si27aMBSGA7kQEu5boVAojLVrkzh2wMd2Esg9lLbT9v6PsznQSpv06+hYOt+5WL+yycpplM+ifBpm0yidx9niUKzScp3mqySfR/I5awpmcTGgseaB5rG2C4oDCnn/+XR6+168PNZvP+pX5/z2dJIRfv1+KOpNWq2SahZmc0keNMQ0T0r1mIQfy/O3/LQ6lotjsc6qbVmvs2qZFNu8eihPN+JwI5IbkfZ3kerCBVM/Jz/W79vytC3q+7TeFuen0+s6rzZ5JTfPqts4m0W5HYR2IEzCu0RoiKketByqPFPlPq3HnN/GUXNbtkyq++y0TOt5c+pXkd8eyvmxXCTVMq3XxcuIJf39ccQSa39QJvRoBZEdHEaMDSkYiOuIm5gPIBpBPIRoCFFvL3TEu1hYRHR83iWhgUMNccXGQnOpjsD0uUV4x29O8ljH5wOIh+wwoGFvF+oeMzHvBcJuatoOKA5VbBJqHuiImT6bED7BbIDAdsHyoOezMY3H/DiEqBnIezvR38nhqsdaLij9INQvMGJ3mG0w3CGYuvSLCxMPJggG+2jEj10iTMz7+2hIQ+MTtkgDe6yD2IKwJWZ3Pnt26dSFFmIGYhZmAxpZgTCx6O1jeyfhtgsSNrFQXQkbCEaEDwkfBXzsMxuxS0fLZxaRX2gSYWChNib5gH3RcqgmS+FSJCPhHcx1j+lyOMiDsWg3rmp9WEXCui8Uh6ouGD63SQNcmauT2i4YCEwipKUatV1QvSZXUQN7oPvc9K+wdmkhk2Y95x+1GslcQ0J5ppdNtI+ZhvwCebDpM9W9bvtfi7/6A51Rim7JuKYvAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/27821a78f0ceee86b443837ca01ed12d/e4706/IOBroker_01.avif 230w", "/en/static/27821a78f0ceee86b443837ca01ed12d/d1af7/IOBroker_01.avif 460w", "/en/static/27821a78f0ceee86b443837ca01ed12d/b6582/IOBroker_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/27821a78f0ceee86b443837ca01ed12d/a0b58/IOBroker_01.webp 230w", "/en/static/27821a78f0ceee86b443837ca01ed12d/bc10c/IOBroker_01.webp 460w", "/en/static/27821a78f0ceee86b443837ca01ed12d/87ca7/IOBroker_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/27821a78f0ceee86b443837ca01ed12d/81c8e/IOBroker_01.png 230w", "/en/static/27821a78f0ceee86b443837ca01ed12d/08a84/IOBroker_01.png 460w", "/en/static/27821a78f0ceee86b443837ca01ed12d/e9beb/IOBroker_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/27821a78f0ceee86b443837ca01ed12d/e9beb/IOBroker_01.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Make sure that you are up-to-date with your software-base`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/88c3604ddc90a74642d2cd95d2d8d441/e9beb/IOBroker_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABMklEQVQY012O2U7CQABFIWA0mIipCFWEgFALU0ohdIbSfZnOTgH//2OMhAQ0ublvJ+fUlkwO4nKas7jkjCtMBGZyxYVRUKNgBubDhEyx0FD05AYNC9bm7nUW4YAIR1YGEf0Ut1HwjALdi15R0IGhdl57E94tUNOC/+Gul6z3B5srt/pGx5Mj1IJLRypA+TjFPS/RYPiyjVqrXQPAhuX+gYcxtvgeUOmo45yqGeEmkTMqP3P6FuQaijvbuOenrZV3MYMbWN+llqhmVNnnH2V0EJeTgo5zqgepBoOmBe9t+GBfsuvgptzEwsDcKPmCK8DkKKNTzG2hlvtqczzNqeh4yXuYdb1IQ+Hj2q/fZjviYBJpEgmYdGT1hfk4Z7qfDRNsED7BdJDgj7joR1l74zd/zVf4BxqsUILeFBctAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/88c3604ddc90a74642d2cd95d2d8d441/e4706/IOBroker_02.avif 230w", "/en/static/88c3604ddc90a74642d2cd95d2d8d441/d1af7/IOBroker_02.avif 460w", "/en/static/88c3604ddc90a74642d2cd95d2d8d441/b6582/IOBroker_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/88c3604ddc90a74642d2cd95d2d8d441/a0b58/IOBroker_02.webp 230w", "/en/static/88c3604ddc90a74642d2cd95d2d8d441/bc10c/IOBroker_02.webp 460w", "/en/static/88c3604ddc90a74642d2cd95d2d8d441/87ca7/IOBroker_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/88c3604ddc90a74642d2cd95d2d8d441/81c8e/IOBroker_02.png 230w", "/en/static/88c3604ddc90a74642d2cd95d2d8d441/08a84/IOBroker_02.png 460w", "/en/static/88c3604ddc90a74642d2cd95d2d8d441/e9beb/IOBroker_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/88c3604ddc90a74642d2cd95d2d8d441/e9beb/IOBroker_02.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7829b0abf02b3d66488163565088bf8a/e9beb/IOBroker_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "15.217391304347828%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAApUlEQVQI103LzQqCMAAA4OlK62ZZgaBBdSgnWwf3Y83pXH8KVtihS+//HgURBN/1A1HV+FkVqNNKmte+fEp956pj0myFlTAbUYiojdIh4S4RIKZfEDGYMLBuH8vLlbTdor6FxyZQB48pl4ge5gMiHMwdzPtYwIRZvwliasXURhTMdR0W52lmIq0DJWc746XSo3IiijHLfZb7PB/x0iEZ2KTg73+8AVXuHguzk1BDAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7829b0abf02b3d66488163565088bf8a/e4706/IOBroker_03.avif 230w", "/en/static/7829b0abf02b3d66488163565088bf8a/d1af7/IOBroker_03.avif 460w", "/en/static/7829b0abf02b3d66488163565088bf8a/b6582/IOBroker_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7829b0abf02b3d66488163565088bf8a/a0b58/IOBroker_03.webp 230w", "/en/static/7829b0abf02b3d66488163565088bf8a/bc10c/IOBroker_03.webp 460w", "/en/static/7829b0abf02b3d66488163565088bf8a/87ca7/IOBroker_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7829b0abf02b3d66488163565088bf8a/81c8e/IOBroker_03.png 230w", "/en/static/7829b0abf02b3d66488163565088bf8a/08a84/IOBroker_03.png 460w", "/en/static/7829b0abf02b3d66488163565088bf8a/e9beb/IOBroker_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7829b0abf02b3d66488163565088bf8a/e9beb/IOBroker_03.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Configure RaspberryPi with `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`sudo raspi-config`}</code></li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/e9beb/IOBroker_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACOklEQVQoz3WOXW/SYBTH+0H0alDo6uaMWfRj6QeYsbSwhLFpvPLOCBnFUkAYLy1teWufB0afshYtxW0wYGOvssUsRr0zHYnRC5N/Tn455/xODvb0hbS6Jj1ZEx/SdSIEfTTAaeClgYcGC4H/hNI8Qf3eswTmp1SCauAvGx5K9dJgLs/zZ9tLA5yBOAM9NPxHJkNweR2SIehjIBGEfsYFHw3xv64QQbd5J7sfeSjNO5dX1qGfAXhA89MaGQR+GpAMWAoCggG+gOYLuCMfrd2xCx5KXaBUfC4/DjcJRltcbz7YRCuv0NJdXX2NHm2h5YhORnRyQyc2dHITLUYQEUHesO4NQjzUvv88gdVaWqXdVCRRTvO1DN9IsGqaAykO8Fw9wVbjbJXjJJYtx2JiNFbYZnei2zlBkA20FYtjZwfg5hiN9JKReWcXopMqN65yvWLUKUZ7+Wg3994pbQ+V5EhKDER2ICb2i/EBKs9OzWyexYZ9ODncdT6rFSg0dblrq11brbdE0JbqrXK9JUEk61b9cIj2B256B7rjNKdHHS7LYoMeGB+0bLMKtXy3o9hmzbGqu82SaUh7qGy0hXZLsAy5360d9eFRHx468IsNToZG8mMcuxqj61PrZmrenlu359bF2JgO9e+X3V/X9s+Z/ePrp9nUnB13ribo25k1OzEvjvdOR8btpV0QU1ipnC6KfLGckpS0UnE5k/9QEHi5kqmpO7VGTpDTopwWJV5WMgUxlRP4TCEpKNnw2ze/AZIhpJR3nt8nAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/e4706/IOBroker_04.avif 230w", "/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/d1af7/IOBroker_04.avif 460w", "/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/b6582/IOBroker_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/a0b58/IOBroker_04.webp 230w", "/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/bc10c/IOBroker_04.webp 460w", "/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/87ca7/IOBroker_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/81c8e/IOBroker_04.png 230w", "/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/08a84/IOBroker_04.png 460w", "/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/e9beb/IOBroker_04.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/e9beb/IOBroker_04.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`First, change the default password:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/e9beb/IOBroker_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACOklEQVQoz3WOXW/SYBTH+0H0alDo6uaMWfRj6QeYsbSwhLFpvPLOCBnFUkAYLy1teWufB0afshYtxW0wYGOvssUsRr0zHYnRC5N/Tn455/xODvb0hbS6Jj1ZEx/SdSIEfTTAaeClgYcGC4H/hNI8Qf3eswTmp1SCauAvGx5K9dJgLs/zZ9tLA5yBOAM9NPxHJkNweR2SIehjIBGEfsYFHw3xv64QQbd5J7sfeSjNO5dX1qGfAXhA89MaGQR+GpAMWAoCggG+gOYLuCMfrd2xCx5KXaBUfC4/DjcJRltcbz7YRCuv0NJdXX2NHm2h5YhORnRyQyc2dHITLUYQEUHesO4NQjzUvv88gdVaWqXdVCRRTvO1DN9IsGqaAykO8Fw9wVbjbJXjJJYtx2JiNFbYZnei2zlBkA20FYtjZwfg5hiN9JKReWcXopMqN65yvWLUKUZ7+Wg3994pbQ+V5EhKDER2ICb2i/EBKs9OzWyexYZ9ODncdT6rFSg0dblrq11brbdE0JbqrXK9JUEk61b9cIj2B256B7rjNKdHHS7LYoMeGB+0bLMKtXy3o9hmzbGqu82SaUh7qGy0hXZLsAy5360d9eFRHx468IsNToZG8mMcuxqj61PrZmrenlu359bF2JgO9e+X3V/X9s+Z/ePrp9nUnB13ribo25k1OzEvjvdOR8btpV0QU1ipnC6KfLGckpS0UnE5k/9QEHi5kqmpO7VGTpDTopwWJV5WMgUxlRP4TCEpKNnw2ze/AZIhpJR3nt8nAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/e4706/IOBroker_04.avif 230w", "/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/d1af7/IOBroker_04.avif 460w", "/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/b6582/IOBroker_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/a0b58/IOBroker_04.webp 230w", "/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/bc10c/IOBroker_04.webp 460w", "/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/87ca7/IOBroker_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/81c8e/IOBroker_04.png 230w", "/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/08a84/IOBroker_04.png 460w", "/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/e9beb/IOBroker_04.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d92d5abfb3ada3e0b4953bb69ac41b37/e9beb/IOBroker_04.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Set your location and language`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5da3d2ac044dea7edef624cc49090128/e9beb/IOBroker_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABUklEQVQY0xXEW0+CUAAAYP59j73UyqitKRA3FcELiZkBoYJ4RDzncFARqHQ4XZe1npvbt4/KiZ3HToIsGAzWcLABRho+n571EtBb+sY6GCxBPw3NTWiuIocgBy3sdOkqLZHK4mGRTmJomq8dz+/iyI6w6U66E9/wfGPsGT7oL6AVxyNCRnE0RNiJkLPLQFOXqYw4b4kbT5/MFu/q4sJsQFu1dWHUlay2YOviuFedvTQ2Xnc90lfjR+K0yWzwkU61jkQlyM4Sl1hqwF6F5cv5/UXE01C+xRKNhRss0TO2hKU7ItDkoRTzdMRcY6v5loFGW6L2G+/4Do7b4OsAf45ot53nOfg6wL8f8vdNfj/xoYD73bzYBt9HfNijokAfOfjchUa/QckqLyqMqLBKk1U1Vq4znFyW6oyica1HsaWLVZWraaxywgk1hqtWKnJF0vizy/N/Hc8WogIB1/YAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5da3d2ac044dea7edef624cc49090128/e4706/IOBroker_08.avif 230w", "/en/static/5da3d2ac044dea7edef624cc49090128/d1af7/IOBroker_08.avif 460w", "/en/static/5da3d2ac044dea7edef624cc49090128/b6582/IOBroker_08.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5da3d2ac044dea7edef624cc49090128/a0b58/IOBroker_08.webp 230w", "/en/static/5da3d2ac044dea7edef624cc49090128/bc10c/IOBroker_08.webp 460w", "/en/static/5da3d2ac044dea7edef624cc49090128/87ca7/IOBroker_08.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5da3d2ac044dea7edef624cc49090128/81c8e/IOBroker_08.png 230w", "/en/static/5da3d2ac044dea7edef624cc49090128/08a84/IOBroker_08.png 460w", "/en/static/5da3d2ac044dea7edef624cc49090128/e9beb/IOBroker_08.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5da3d2ac044dea7edef624cc49090128/e9beb/IOBroker_08.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Then go the `}<strong parentName="li">{`Advanced Options`}</strong>{` and expand the file system to use the complete available storage space.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/afda9c19ed9055003bd21b3e7fa06ba1/e9beb/IOBroker_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABUklEQVQY0wXBS1OCQAAAYP7/oVPTTKNTM5kumyEgApqW8hRdc3FZWWR5lKY9tMYOTte+T+DUKhJ/FXsL6iaJly6GPHZ4NOKRlRKL4dFq6cbE5rGfsjHjiK6mhPppMtF6LaFgztd6nvn65OYivKvk5m2h1zC8jOSrRbOKm1UiXXO9XhigMMVcB1yrZ9P+7hX3HxUhj51tiVL0YMs3sy5MbJVZqq3UpiZ0tYargYkpksfWS2CUQbcITO4ZaTh6K597A0VII2eTBYza09kgXrqM+Qnz5viJUotSi5DhbD5cEGuVeGWOyhxlGUrZeJ2j7kAWdlnw/RYeNvi4w79bvCvQmk+O7+HfgZ720emLHDZ4v559vqDjNtxv8MfrfFs+/7xHtqMLTRVI7YakAc0UOyaUNSDKt/ftRtuA5kDq9u8VHSq6qBpAM2CzDaDaAHJd6sCzyvk/EzMX+QBWIyYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/afda9c19ed9055003bd21b3e7fa06ba1/e4706/IOBroker_05.avif 230w", "/en/static/afda9c19ed9055003bd21b3e7fa06ba1/d1af7/IOBroker_05.avif 460w", "/en/static/afda9c19ed9055003bd21b3e7fa06ba1/b6582/IOBroker_05.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/afda9c19ed9055003bd21b3e7fa06ba1/a0b58/IOBroker_05.webp 230w", "/en/static/afda9c19ed9055003bd21b3e7fa06ba1/bc10c/IOBroker_05.webp 460w", "/en/static/afda9c19ed9055003bd21b3e7fa06ba1/87ca7/IOBroker_05.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/afda9c19ed9055003bd21b3e7fa06ba1/81c8e/IOBroker_05.png 230w", "/en/static/afda9c19ed9055003bd21b3e7fa06ba1/08a84/IOBroker_05.png 460w", "/en/static/afda9c19ed9055003bd21b3e7fa06ba1/e9beb/IOBroker_05.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/afda9c19ed9055003bd21b3e7fa06ba1/e9beb/IOBroker_05.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c0d3f1e33173a4413d714e39cd748c55/e9beb/IOBroker_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADHElEQVQoz1WS22sTaRjG85d4IeIq4mGpqIu0CisKCuqFggdYRGQRr9wbXZW6dQ02x6ap1WA9VNTFw94uuHFmsm3jfMc5JBMPmcx8M5nMZJo0bVSs4KV80dUKz8X3Xvz4nud53whAAqUSQgKEzygRCRYIFlQqVqhYUQolIhpqoVyaNkrTL4wZozxjlKYJlQJGcw/vRyjJK/hfXS0oVNQUSaUSwYKmSLpW0NQCwoKiSBoVVUXUVElVJUpFCPNuDY1P5iIVTbIrBd8sOq+mQkvuuKjjIt9CjomcKmQ9OSa0q9A2ITOh9RpWX8rthn79/kSE6ODkPXjynnz6L/nUA3j0Njw2ifQK6XqkYWMuCzdsEjDiMz46NcSqgNmlkTsTkf8I2JgAW5JgYBT1JdGyS3D5n3BGw4sBCRlpMtxk2LO+qF7DzOSwZevpOxORKSzvzMq7r8q7x8GeHNqUQj8moKyT9wEJnc88t+BZ2LVw3foftnpwHsOBMbxjHG0fQz9foxvTeH0CFXXyLuBWe8JBz7Bnf4NtW+e25fw/J84+OD745NfBR0cu/L0hOrNqGEoUz3ukbuHPsX2b89y2hb6Dpwri3ujTfQnxQErcExPWXi6uvgKfl5UPLbXdUOY86lk4YDyC3zPPTGx/hQUMf8ri/izalcP9WbwqCn6IggLFi7NqJ9DnfdVnJHRJ26PtOuVtm/jbzxKS+9PyrjF5RxZsTYMNMbAyCgSMug3cbqgLzVLo0tAlcw0OexxGS2EwkAHbMmDnVbglDdfF4JorHO7UedSOr7XqSuCQOV9reZQXVlsCPwVyXwJsToJto7AvCVdE+Z7ziMMBwy2Hp226NHDol7ZrS/YswuKhm/Ivt+QTd8Hhm2B/jquowpDxq/Qs6NuI1aBVhXYVOCa0qqD26rlZ01K3b0QCR11oGgtNY7FdeTdrtD193i+9DY1uaCw0y92w/L5dmfW0wFU7QflNszwflFue/rFrTj6+Gzk3mh1MD1/MJIdGk0OZxMWR2B8jsaFMvPeOn0vFz6fiZxPDZxLD51PxC2k+/p6MXRpLHzz92ydGPsKBQOQlawAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c0d3f1e33173a4413d714e39cd748c55/e4706/IOBroker_06.avif 230w", "/en/static/c0d3f1e33173a4413d714e39cd748c55/d1af7/IOBroker_06.avif 460w", "/en/static/c0d3f1e33173a4413d714e39cd748c55/b6582/IOBroker_06.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c0d3f1e33173a4413d714e39cd748c55/a0b58/IOBroker_06.webp 230w", "/en/static/c0d3f1e33173a4413d714e39cd748c55/bc10c/IOBroker_06.webp 460w", "/en/static/c0d3f1e33173a4413d714e39cd748c55/87ca7/IOBroker_06.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c0d3f1e33173a4413d714e39cd748c55/81c8e/IOBroker_06.png 230w", "/en/static/c0d3f1e33173a4413d714e39cd748c55/08a84/IOBroker_06.png 460w", "/en/static/c0d3f1e33173a4413d714e39cd748c55/e9beb/IOBroker_06.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c0d3f1e33173a4413d714e39cd748c55/e9beb/IOBroker_06.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0edb7e04665ee59d92b6078ef04e576c/e9beb/IOBroker_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "123.91304347826089%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAIAAAC+dZmEAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEPklEQVQ4y02US2/bRhSF9RP7U9omSNpNAzR281jktcuqm9pxEBhpYztpJNmiKPExMyRNURQfM+RwhkNKpF4FuuyquFRSFLgQIEjfnLnnnjs9jPqEDFwyIBjKwQOM+gvrT3f4Phhf+JMrt39u6pcIDxEaYDS00cCw+tHs+tX5p57nDGfeyHev6cLIYrMrI6d2mliMoiQ2o8iMF0YaGSwys8TKYjNZGK0gL05Oe/Fcz2KDRlPJUMmQpPDJE3MR6ItAZ7EpGJIZqnJccVzlWGWYU7Qt3ecnZz2emnVOVIYlQ5IhQe0yw1GgD4bvdf2KRUY81+f+uOLwh4IiTm2WWK10AC4So85x2clWoIDqHMfhpD+5Iv5YlrMwNHxvVIKgzaldUDtLrW3pvDw960lqrjhWHdxwvBVoIx3mDUe/vbbe/SoH7+aTy3mgVzkuM6RypDJUUHun3FdvznolsxpB6hwtOW4KgHeVp9y+9/KYPH0QPv4p/HBygCUDsszg5tvSfXF61qsyay1JUwC5FngryV4Rnk5G1ifDvRbKp4k5924Ux4KCKQCnoAzXXhb2pnQa8R+M98rhC204OJ+OL2pm0WAczbSKE8FscJShPLV2ygHlOrf3lbsWeCVIK8kGlB1FDcu4NM2rIjVoqC98rS5I2d35AG/Kzm2RmNuS7JS7Lp2tcjals1NOSY2J/uHWGYjUSuZ66GurgqjOMNkZtlddzzwxVhwvOa4hA3B2neNkrt9oF/r0Y57aUTCe344O8EE873oGOI+NkmFBbUHhh4qTlXBYPLXMj4T0k4UeBeOZN6oLAhHg3bXB7a5nxcy6G4PMUM3xRjl/rW4rblnmle8OltxO5trMGy0L0ghIqMzA8y0ov+2V1GwKSJjo4qkytK89yUyCP+fJVDIzDXXfHS2F0wqyKnAroLtd6b5887anMrMtSN2dJ7vFWEKMTYQ+l5n1d+Pn0WTmjRrptJI0gqwlUTmCOQPMLDiSY/WVr3MskqmuX7rusFVukRqhrx3gVjqbEmYGyoeEtQUk7GC1oNA5DfWbm9/n/k2j3IJaiw5eCmer3I1yAD64fVBuC5jWQbzOSehr16M/FrNRKzCnVhyMG0GWwlkrr5X/hzOAIWEFjBoWMydxMNa0izjQVhzlkR7NtFY6tXBWwj2EZHNYjJKZTUFagRsB4vBi5CQKtOub99FM25ZuEozDW3Bb5kQwWKk8/QorZi45GNYUePmlSDTXp5OrGDYRnqQitWSGsxQweBJgMTpYpgbEK7UFtSW12GLKU4undpHasns0VgUWzA7nExqZLLFYbNHI3Ejy/ORNb1s6+zrY1bN9PduqW0HtrfJ31WxT+evK31T+rvKL7t1aSW+t/LXyV+XtP7vw9fl57/6zZ/ce/Xzv8dGhfnx6fP/xEdSTozu/PPzuGOrOo6O7j46+P/7y9dvjhz88efjN3Qf/AqNJukeH9YsUAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0edb7e04665ee59d92b6078ef04e576c/e4706/IOBroker_07.avif 230w", "/en/static/0edb7e04665ee59d92b6078ef04e576c/d1af7/IOBroker_07.avif 460w", "/en/static/0edb7e04665ee59d92b6078ef04e576c/b6582/IOBroker_07.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0edb7e04665ee59d92b6078ef04e576c/a0b58/IOBroker_07.webp 230w", "/en/static/0edb7e04665ee59d92b6078ef04e576c/bc10c/IOBroker_07.webp 460w", "/en/static/0edb7e04665ee59d92b6078ef04e576c/87ca7/IOBroker_07.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0edb7e04665ee59d92b6078ef04e576c/81c8e/IOBroker_07.png 230w", "/en/static/0edb7e04665ee59d92b6078ef04e576c/08a84/IOBroker_07.png 460w", "/en/static/0edb7e04665ee59d92b6078ef04e576c/e9beb/IOBroker_07.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0edb7e04665ee59d92b6078ef04e576c/e9beb/IOBroker_07.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`Finish the Configuration and Login with your new Password:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0d335d50a88d0fe9d5f9afb550ee710f/e9beb/IOBroker_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABUElEQVQY0xXCSVOCYAAAUP7/rYNjNlpjAikooJCTormwibIp6/exio6KpZMXuza9eUgOxBzKsSt41iRxpqnBZ5vP1OQza5QafKTxiTUJjXG2niabWehJwBEDRwpcybMFZAuVIltBT5wrH0ud96EEoKwaI80cLY2xqo/19cwFSphoIF7BaOlBFQI1hSrwZGQLpH26ggKr1EsWUQXUi9OsKGhZxysLtLxAH9V6afn8AIkqJGqArHn4E5D6SbQCroQkvpjHqq8N9SEZiGwgcMG8t55Q7pzx5owzZXS+bfFkMGNiuR/L/VB4h+YkggvfEZFzql331uVg3Qr7VmyOublL9dvZ+f3x71f/fnEvJ/vrsC725u3sfB/t4mAfc+u0NXexhpDdZotutBiU5vAuh5MMirVfWzRGszg3ILkB0e7hnR5GsRjNvjVpDKNQtPO/0W78Ab9qGGi2GgK/AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0d335d50a88d0fe9d5f9afb550ee710f/e4706/IOBroker_09.avif 230w", "/en/static/0d335d50a88d0fe9d5f9afb550ee710f/d1af7/IOBroker_09.avif 460w", "/en/static/0d335d50a88d0fe9d5f9afb550ee710f/b6582/IOBroker_09.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0d335d50a88d0fe9d5f9afb550ee710f/a0b58/IOBroker_09.webp 230w", "/en/static/0d335d50a88d0fe9d5f9afb550ee710f/bc10c/IOBroker_09.webp 460w", "/en/static/0d335d50a88d0fe9d5f9afb550ee710f/87ca7/IOBroker_09.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0d335d50a88d0fe9d5f9afb550ee710f/81c8e/IOBroker_09.png 230w", "/en/static/0d335d50a88d0fe9d5f9afb550ee710f/08a84/IOBroker_09.png 460w", "/en/static/0d335d50a88d0fe9d5f9afb550ee710f/e9beb/IOBroker_09.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0d335d50a88d0fe9d5f9afb550ee710f/e9beb/IOBroker_09.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a784b989c35f87bfd1e8c7d7ee3ec9b4/e9beb/IOBroker_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABT0lEQVQY0x3JyW6CQAAAUP7/B5oUNak6iOgoBK1bLdZlBhRRRGQbGERcWq1JbdJ4bNrbSx5D3VE87xKYI9JT0sgnFXZXy+5rmbSa2ZbZuMzGMOfzjx7/6PDsWsiuSuwy/+AYysaeMNRFkaVMZGD06x+rvodkvSeYCjRfodbipx1h/gJxW1gOpIUi2UPZfpNXihg6yHcwQ52xa4+w2sZaN/KxtRqMUQtrHW3WG6KWMnq2rKHvIUq0hOpbqtNQj0I98jVvM2GC9TD2MXUnsYdiHxMXUQ+loZqG6o6oSYCIi5Lgz9RXI08lDg4cHLjYscfMgajX/fIzXdyO5vfRfI+NlEy/jub9at8v9s/Zuu7NS2Kck/ntaJ53i1OyOMTz09bYhTOmLHIVEQgSEJuc1OSqEuBgQRBBvVFqdivNbgXKJShz/8uXRcDVAagVQa1YhIVfKeMYl05ZdSUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a784b989c35f87bfd1e8c7d7ee3ec9b4/e4706/IOBroker_10.avif 230w", "/en/static/a784b989c35f87bfd1e8c7d7ee3ec9b4/d1af7/IOBroker_10.avif 460w", "/en/static/a784b989c35f87bfd1e8c7d7ee3ec9b4/b6582/IOBroker_10.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a784b989c35f87bfd1e8c7d7ee3ec9b4/a0b58/IOBroker_10.webp 230w", "/en/static/a784b989c35f87bfd1e8c7d7ee3ec9b4/bc10c/IOBroker_10.webp 460w", "/en/static/a784b989c35f87bfd1e8c7d7ee3ec9b4/87ca7/IOBroker_10.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a784b989c35f87bfd1e8c7d7ee3ec9b4/81c8e/IOBroker_10.png 230w", "/en/static/a784b989c35f87bfd1e8c7d7ee3ec9b4/08a84/IOBroker_10.png 460w", "/en/static/a784b989c35f87bfd1e8c7d7ee3ec9b4/e9beb/IOBroker_10.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a784b989c35f87bfd1e8c7d7ee3ec9b4/e9beb/IOBroker_10.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">{`Installation of Node.js`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ff734fb0f35d32c941882e01f6b32d06/e9beb/IOBroker_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABVklEQVQY0wXBWVOCQAAAYH56P6CXXnpsxlBkBTXFAw/a5VowoF3kUFIEHCtouptqmqaZvo/ZJ8b+BqexHvsojdSMzPMAZnSW+0pGlK033y3RlsI81LJI36xxEuM4MIqtAy44prjBVe5uQhUbEnHGm1jdRNBzxsSViTNxFrLvzVYB2iVmujbTlZFEehKZ5Z62JZ7JE3zYLUKqDebDCZKNBcRXaHQ5nKryUBmNLsczbarbCvWxSy3Ht+xrHAT4vrgW+01mtzIOqY09vTGFtSk6m6gAGiLSWkgHUBOQVp9BQTOBbjehxSPrXLFM17wvvFavyTzk7tOBvt6S74r8VPRl71bZ4quify/h33P4+7h8L/2PFfq0ez8l+Sj9tztSFt5rGQ9kkRF6POiy4KLekbiOxIFugxVZvtvoSFxfFiS5JUi8CM4656ftXoPrcmy7XhNYoQ+OTo7/AZ9fFvG/8HwCAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ff734fb0f35d32c941882e01f6b32d06/e4706/IOBroker_11.avif 230w", "/en/static/ff734fb0f35d32c941882e01f6b32d06/d1af7/IOBroker_11.avif 460w", "/en/static/ff734fb0f35d32c941882e01f6b32d06/b6582/IOBroker_11.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ff734fb0f35d32c941882e01f6b32d06/a0b58/IOBroker_11.webp 230w", "/en/static/ff734fb0f35d32c941882e01f6b32d06/bc10c/IOBroker_11.webp 460w", "/en/static/ff734fb0f35d32c941882e01f6b32d06/87ca7/IOBroker_11.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ff734fb0f35d32c941882e01f6b32d06/81c8e/IOBroker_11.png 230w", "/en/static/ff734fb0f35d32c941882e01f6b32d06/08a84/IOBroker_11.png 460w", "/en/static/ff734fb0f35d32c941882e01f6b32d06/e9beb/IOBroker_11.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ff734fb0f35d32c941882e01f6b32d06/e9beb/IOBroker_11.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4802fd4eab1f4980cbac019a05a4cda0/e9beb/IOBroker_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "4.3478260869565215%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAALklEQVQI12OQVzcR0DDhM7IWMbcXNDHnN9ZhUDNi1jBkgiN1QwZ1Q0Z1KAMZAQD4Nwe4+didUAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4802fd4eab1f4980cbac019a05a4cda0/e4706/IOBroker_12.avif 230w", "/en/static/4802fd4eab1f4980cbac019a05a4cda0/d1af7/IOBroker_12.avif 460w", "/en/static/4802fd4eab1f4980cbac019a05a4cda0/b6582/IOBroker_12.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4802fd4eab1f4980cbac019a05a4cda0/a0b58/IOBroker_12.webp 230w", "/en/static/4802fd4eab1f4980cbac019a05a4cda0/bc10c/IOBroker_12.webp 460w", "/en/static/4802fd4eab1f4980cbac019a05a4cda0/87ca7/IOBroker_12.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4802fd4eab1f4980cbac019a05a4cda0/81c8e/IOBroker_12.png 230w", "/en/static/4802fd4eab1f4980cbac019a05a4cda0/08a84/IOBroker_12.png 460w", "/en/static/4802fd4eab1f4980cbac019a05a4cda0/e9beb/IOBroker_12.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4802fd4eab1f4980cbac019a05a4cda0/e9beb/IOBroker_12.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Deinstall old node.je Version:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`apt-get --purge remove node
apt-get --purge remove nodejs
apt-get autoremove
reboot`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/165e7a8433b9633dd15d23b94ba8f096/e9beb/IOBroker_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "17.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAArklEQVQI1yXLuw6CMBQAUAKIhM0YTVR80aiJ5VLU9oKoFLAFkUEH//9bjHE7yzGIbmdSE9XuCl2eC5nJ9JTFER6iJAZkgKc4pYAu5T0QDggnFHYoLCrMPTeO70+gn9C9JrKe151f1st7O0jl+Fqt9WN4zh2Gbpw4gDYVLgg3Qpty+5+31WNV6OnlvswlKQvyczVK5SJXG9VsVOPfyqBSA55ZlHsMPZZ4DPuA5l58ATCdIRWvVREdAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/165e7a8433b9633dd15d23b94ba8f096/e4706/IOBroker_13.avif 230w", "/en/static/165e7a8433b9633dd15d23b94ba8f096/d1af7/IOBroker_13.avif 460w", "/en/static/165e7a8433b9633dd15d23b94ba8f096/b6582/IOBroker_13.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/165e7a8433b9633dd15d23b94ba8f096/a0b58/IOBroker_13.webp 230w", "/en/static/165e7a8433b9633dd15d23b94ba8f096/bc10c/IOBroker_13.webp 460w", "/en/static/165e7a8433b9633dd15d23b94ba8f096/87ca7/IOBroker_13.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/165e7a8433b9633dd15d23b94ba8f096/81c8e/IOBroker_13.png 230w", "/en/static/165e7a8433b9633dd15d23b94ba8f096/08a84/IOBroker_13.png 460w", "/en/static/165e7a8433b9633dd15d23b94ba8f096/e9beb/IOBroker_13.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/165e7a8433b9633dd15d23b94ba8f096/e9beb/IOBroker_13.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/96619cee18b903af9d6e94751e00ea7f/e9beb/IOBroker_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "13.043478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAr0lEQVQI1x3LTQuCMBgAYC9GtaVYVGJYUFCHlk50e+eWa370SXWJbh37/78h8vzwWHN9DMzxVFTfovyY+iWKe6reQj8oYAouk26WO6lACdhb3o0AJ4Ba6hBmkdtzc72H1WVdnxe5XkozosIhbBABosLL1Dg/TKT2mLQJ68ccJ/+PKdiEW4GqhrxcNios976sfGXcTHlw8Jge8WKY7WeqXDWXqTSdHevFgBLhpDlq8w9TAyDG8/YfnAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/96619cee18b903af9d6e94751e00ea7f/e4706/IOBroker_14.avif 230w", "/en/static/96619cee18b903af9d6e94751e00ea7f/d1af7/IOBroker_14.avif 460w", "/en/static/96619cee18b903af9d6e94751e00ea7f/b6582/IOBroker_14.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/96619cee18b903af9d6e94751e00ea7f/a0b58/IOBroker_14.webp 230w", "/en/static/96619cee18b903af9d6e94751e00ea7f/bc10c/IOBroker_14.webp 460w", "/en/static/96619cee18b903af9d6e94751e00ea7f/87ca7/IOBroker_14.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/96619cee18b903af9d6e94751e00ea7f/81c8e/IOBroker_14.png 230w", "/en/static/96619cee18b903af9d6e94751e00ea7f/08a84/IOBroker_14.png 460w", "/en/static/96619cee18b903af9d6e94751e00ea7f/e9beb/IOBroker_14.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/96619cee18b903af9d6e94751e00ea7f/e9beb/IOBroker_14.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Then install Node.js anew for Raspbery 2/3:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`sudo -s
curl -sL https://deb.nodesource.com/setup_10.x | sudo -E bash -
sudo apt-get install -y build-essential python-rpi.gpio python nodejs
reboot`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/02b40b687b8889ae040e02bec8adef70/e9beb/IOBroker_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "5.217391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAARUlEQVQI12NQdPJRcvIRsXCWsnFXdPISMHPg0LfmN3UUMHUQMHUUNneSc/aWd/FV9vBXcfeXcvCUc/GRcfJScvdXcHUCAG4lDHX/Ta8zAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/02b40b687b8889ae040e02bec8adef70/e4706/IOBroker_15.avif 230w", "/en/static/02b40b687b8889ae040e02bec8adef70/d1af7/IOBroker_15.avif 460w", "/en/static/02b40b687b8889ae040e02bec8adef70/b6582/IOBroker_15.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/02b40b687b8889ae040e02bec8adef70/a0b58/IOBroker_15.webp 230w", "/en/static/02b40b687b8889ae040e02bec8adef70/bc10c/IOBroker_15.webp 460w", "/en/static/02b40b687b8889ae040e02bec8adef70/87ca7/IOBroker_15.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/02b40b687b8889ae040e02bec8adef70/81c8e/IOBroker_15.png 230w", "/en/static/02b40b687b8889ae040e02bec8adef70/08a84/IOBroker_15.png 460w", "/en/static/02b40b687b8889ae040e02bec8adef70/e9beb/IOBroker_15.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/02b40b687b8889ae040e02bec8adef70/e9beb/IOBroker_15.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/249a6f8539dc8177c1a8546539294b11/e9beb/IOBroker_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6.521739130434782%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAIAAABR8BlyAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAQElEQVQI12NQ9wySsHUXtHL1MXeItXAINbcPNLIJMLL2NbAKNLIJMrGNsHSMsHQKt3AMs3AIB7FB3FhrG1krRwDF8A8s0RnxrQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/249a6f8539dc8177c1a8546539294b11/e4706/IOBroker_16.avif 230w", "/en/static/249a6f8539dc8177c1a8546539294b11/d1af7/IOBroker_16.avif 460w", "/en/static/249a6f8539dc8177c1a8546539294b11/b6582/IOBroker_16.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/249a6f8539dc8177c1a8546539294b11/a0b58/IOBroker_16.webp 230w", "/en/static/249a6f8539dc8177c1a8546539294b11/bc10c/IOBroker_16.webp 460w", "/en/static/249a6f8539dc8177c1a8546539294b11/87ca7/IOBroker_16.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/249a6f8539dc8177c1a8546539294b11/81c8e/IOBroker_16.png 230w", "/en/static/249a6f8539dc8177c1a8546539294b11/08a84/IOBroker_16.png 460w", "/en/static/249a6f8539dc8177c1a8546539294b11/e9beb/IOBroker_16.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/249a6f8539dc8177c1a8546539294b11/e9beb/IOBroker_16.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/edf37845f594edff150245762e3cb86a/e9beb/IOBroker_17.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "21.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA3UlEQVQI1y3NW0+DMBiA4XrjYUajRCfBQ7YIrhS+rQqlLeOcFtbCnPH//xhDYvLcvnmRb36WlQ46sy+VkC1jJaUCk5TELAIWQkaAE8giEAR4tBU4zkLgAPwlTFE4/frK+P34WPVuc3gqlVfpe17fZbUjm4udQDj5FyaIpLOIoblM0WY40nGKDxO1R9yPYE+BsoGykflet8NrpR1Wunm3lO1zqbyic/PuQTQ3X/trmqOtnXBvVu2wbodA2w9tVk3/VuuNMu/d4BXKyerbpLjcyQWVV1QuPuU58DMyn/8ALggt+ZLPNqIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/edf37845f594edff150245762e3cb86a/e4706/IOBroker_17.avif 230w", "/en/static/edf37845f594edff150245762e3cb86a/d1af7/IOBroker_17.avif 460w", "/en/static/edf37845f594edff150245762e3cb86a/b6582/IOBroker_17.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/edf37845f594edff150245762e3cb86a/a0b58/IOBroker_17.webp 230w", "/en/static/edf37845f594edff150245762e3cb86a/bc10c/IOBroker_17.webp 460w", "/en/static/edf37845f594edff150245762e3cb86a/87ca7/IOBroker_17.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/edf37845f594edff150245762e3cb86a/81c8e/IOBroker_17.png 230w", "/en/static/edf37845f594edff150245762e3cb86a/08a84/IOBroker_17.png 460w", "/en/static/edf37845f594edff150245762e3cb86a/e9beb/IOBroker_17.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/edf37845f594edff150245762e3cb86a/e9beb/IOBroker_17.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`After installation of node.js the command `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`node -v`}</code>{` should show the node.js version. if not, create alias to bin file:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`sudo ln -s /usr/local/bin/nodejs /usr/bin/node`}</code></pre></div>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol">{`Set static IP address (optional)`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`sudo nano /etc/dhcpcd.conf`}</code></pre></div>
    <p>{`Call per interface (example):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`interface eth0
static ip_address=192.168.2.115/24
static routers=192.168.2.5
static domain_name_servers=192.168.2.5 1.1.1.1`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2b99962456d54452f36c737d97f0c4b5/e9beb/IOBroker_18.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "85.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACtUlEQVQ4y3WS61PaQBTF86mvaZWHqCSoEAxYlVp1KJAEks1mn2GTAIIiauurL2s7dtpx+vjjO5uOHduxM7/Zb2fPuedehZy8xsfn7OxN9PYifHfR/3CJjs/do1MynPDhPhmM2c4YjSbg5Zn38hQkOIfHL4YTc+9QKThsEXaLvsi7NO+yuQ5Nt2DGhCkLTd+QtvGj7c69zfb932y1H253Hmx3lCqJDRavdPtFJAqAF7yghMMiFkVf5GyUs9GsjXM2nvkLNGNJFINFBcBVlxU8+aqAqS7XAM87VHPZvENTLZhOyJj+PyirQb+EhMFinYQ6lhhMlGmk46hMJYsw0ABbgMEd4irrSTGNn3YHButVWG9VhAaNDdarBv0lvzvbodkkZMb00y3/dgqlBMWCxzU3yQlkYNWVk2suy5j+dBPK2DdW6dvOLais8v6a2KmFw/VwqONoyRfzDlUBz7uskPyVzM+y1o3+VgVKGYUV1luPRss0XkJiye+qrpxQlWsjORvn2jhnoz+GqSZMJXGSwvigFu5sDvaexaNagsF6ZRLpONTk5qTtnVVL8fN4dyMe1Yf7K4FcdRGJBRjkHSbX5vKZW553iEtQlElY5f1K0NNJtAi7c22Ss9Dvw0ibMuR/nXUoDBoZJNKRWKZR0ReaQ9UEzWUaYBrgsx1yt/iFx7dJuIXFBgxqgK851LCQYaGKjaptXG3jlTbWLZQ2/WxS9d8zo+7GcNLYPdzkoxodVEhvXl4lS5lwqulNt/wnTa/UAFkLpZrwT+EZ089aSGlAvgaDOo1M1l9tk+cO3wDBchPWYLDmsaqDt0hYaoLHdUd16FQDTDVA3qHplve47ijjj5/r/Qk/e3/05dqZnAw/XI0/fbHHr/avvkZvL+Hh+cn1d3J2YR+cHn/7CV69hifvjq5/0PML6+D0F1An6WL/WAAuAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b99962456d54452f36c737d97f0c4b5/e4706/IOBroker_18.avif 230w", "/en/static/2b99962456d54452f36c737d97f0c4b5/d1af7/IOBroker_18.avif 460w", "/en/static/2b99962456d54452f36c737d97f0c4b5/b6582/IOBroker_18.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2b99962456d54452f36c737d97f0c4b5/a0b58/IOBroker_18.webp 230w", "/en/static/2b99962456d54452f36c737d97f0c4b5/bc10c/IOBroker_18.webp 460w", "/en/static/2b99962456d54452f36c737d97f0c4b5/87ca7/IOBroker_18.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b99962456d54452f36c737d97f0c4b5/81c8e/IOBroker_18.png 230w", "/en/static/2b99962456d54452f36c737d97f0c4b5/08a84/IOBroker_18.png 460w", "/en/static/2b99962456d54452f36c737d97f0c4b5/e9beb/IOBroker_18.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2b99962456d54452f36c737d97f0c4b5/e9beb/IOBroker_18.png",
              "alt": "ioBroker Installation",
              "title": "ioBroker Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      